import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  id_league: null,
  id_club: null,
  id_athlete: null,
  id_event_selected: null,
  modal_select_event: true,

  inscriptions_modal_change_level: false,
  inscriptions_modal_upload_level: false,

  id_category_selected: null,

  spinner_select_event: true,

  name_category: null,

  itemsDataAthlete: [],
  itemsDataTests: [],
  test_selected: [],

  dataEvents: [],

  itemsInscriptionsAthlete: [],
  itemsInscriptionsList: [],
  dataInscriptionDelete: [],
  loadingDataInscription: false,
  loadingDataPln: false,
  loadingDeleteInscription: false,
  itemsInscrip: []
};

const getters = {};

const mutations = {
  getInscriptions(state, payload) {
    state.loadingDataGender = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptions/${payload.id_athlete}/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsInscriptionsAthlete =  response.data.data
      })
      .catch(function (error) {
        state.loadingDataGender = false;
        console.log(error.response.data.message)
      });
  },

  getInscriptionsList(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptions/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.loadingDataInscription = false;
        state.itemsInscriptionsList =  response.data.data
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },

  delInscription(state, payload) {
    state.loadingDeleteInscription = true;
    var vm = this;
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + `/inscriptions/${payload.id}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "center",
          icon: "success",
          title: "Inscripción eliminada exitosamente",
          showConfirmButton: true,
        });

        vm.commit("inscriptions/getInscriptionsList", { id_event: payload.id_event });
        state.loadingDeleteInscription = false;
        state.dataInscriptionDelete =  response.data.data
      })
      .catch(function (error) {
        state.loadingDeleteInscription = false;
        console.log(error.response.data.message)
      });
  },

  getStartList(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionsstart/startlist/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.loadingDataInscription = false;
        window.open(process.env.VUE_APP_URL_API + response.data.data.route, 'nna', 'height=1105,width=833')
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },

  getInscrip(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionsins/inscrip/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.loadingDataInscription = false;
        state.itemsInscrip = response.data.data
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },

  getResults(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionsres/res/${payload.id_event}`,
      responseType: "blob", // Manejo correcto de archivos binarios
        headers: {
            "Accept": "application/pdf",
        },
    };
    axios(config)
      .then(function (response) {
        // state.loadingDataInscription = false;
        // window.open(process.env.VUE_APP_URL_API + response.data.data.route, 'nna', 'height=1105,width=833')
        state.loadingDataInscription = false;

            // Crear un objeto Blob con los datos del archivo
            const blob = new Blob([response.data], { type: "application/pdf" });
            
            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // Nombre del archivo
            const fileName = `resultados_${payload.id_event}.pdf`;
            link.setAttribute("download", fileName);

            // Simular clic en el enlace para iniciar la descarga
            document.body.appendChild(link);
            link.click();

            // Eliminar el enlace y liberar la memoria
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        if (error.response && error.response.status === 404) {
          alert("Evento sin resultados");
        } else {
          console.error("Error al obtener los resultados", error);
        }
      });
  },

  getEvt(state, payload) {
    state.loadingDataInscription = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionsevt/evt/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function(response) {
        state.loadingDataInscription = false;
        const evt = window.location.href = process.env.VUE_APP_URL_API + response.data.data.routeEvt
        setTimeout(()=> {
          window.location.href = process.env.VUE_APP_URL_API + response.data.data.routePpl
        },3000)
        //window.location.href = process.env.VUE_APP_URL_API + response.data.data.routePpl
      })
      .catch(function (error) {
        state.loadingDataInscription = false;
        console.log(error.response.data.message)
      });
  },
  
  getExcel(state, payload) {
    state.loadingDataInscription = true;

    var config = {
        method: "get",
        url: process.env.VUE_APP_URL_API + "/inscriptions/excel/1/" + payload.id_event,
        responseType: "blob", // Manejo correcto de archivos binarios
        headers: {
            "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
    };

    axios(config)
        .then(function (response) {
            state.loadingDataInscription = false;

            // Crear un objeto Blob con los datos del archivo
            const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
            
            // Crear un enlace temporal para descargar el archivo
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // Nombre del archivo
            const fileName = `Lista_Inscritos_${payload.id_event}.xlsx`;
            link.setAttribute("download", fileName);

            // Simular clic en el enlace para iniciar la descarga
            document.body.appendChild(link);
            link.click();

            // Eliminar el enlace y liberar la memoria
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch(function (error) {
            state.loadingDataInscription = false;
            console.error("Error al descargar el archivo Excel:", error);

            if (error.response && error.response.data) {
                console.log(error.response.data.message);
            }
        });
},


  getPla(state, payload) {
    state.loadingDataPln = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionspla/pla/${payload.id_event}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function(response) {
        state.loadingDataPln = false;
        response.data.data.map((d)=> {
          setTimeout(()=> {
            window.open(process.env.VUE_APP_URL_API + d.route, d.route , 'height=1105,width=833')
            //window.location.href = process.env.VUE_APP_URL_API + d.route
          },1000)
        })
       
      })
      .catch(function (error) {
        state.loadingDataPln = false;
        console.log(error.response.data.message)
      });
  },

  getPlaId(state, payload) {
    state.loadingDataPln = true;
    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + `/inscriptionspla/pla/${payload.id_event}/${payload.id_test}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function(response) {
        state.loadingDataPln = false;
        response.data.data.map((d)=> {
          setTimeout(()=> {
            window.open(process.env.VUE_APP_URL_API + d.route, d.route , 'height=1105,width=833')
            //window.location.href = process.env.VUE_APP_URL_API + d.route
          },1000)
        })
       
      })
      .catch(function (error) {
        state.loadingDataPln = false;
        console.log(error.response.data.message)
      });
  },
};


const actions = {
  getReportInscriptionsLeague({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadingSetInscriptions = true;
      var vm = this;
      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_API +
          `/test/inscriptions/report/league/${payload.idEvent}/${payload.idLeague}`,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(async function (response) {
          window.open(process.env.VUE_APP_URL_API + response.data.data.route, 'nna', 'height=1105,width=833')

          /*  resolve(response.data.data);
          state.loadingSetInscriptions = false; */
        })
        .catch(function (error) {
          state.loadingSetInscriptions = false;
          console.log(error.response.data.message);
        });
    });
  },

  getInscriptions({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getInscriptions", payload);
      resolve();
    });
  },

  getInscriptionsList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getInscriptionsList", payload);
      resolve();
    });
  },

  delInscription({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("delInscription", payload);
      resolve();
    });
  },

  getStartList({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getStartList", payload);
      resolve();
    });
  },

  getInscrip({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getInscrip", payload);
      resolve();
    });
  },

  getEvt({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getEvt", payload);
      resolve();
    });
  },

  getExcel({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getExcel", payload);
      resolve();
    });
  },

  getPla({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getPla", payload);
      resolve();
    });
  },
  getPlaId({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getPlaId", payload);
      resolve();
    });
  },

  getResults({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getResults", payload);
      resolve();
    });
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
