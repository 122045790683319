import Vue from "vue";
import axios from "axios";
import router from "@/router";

const state = {
  loadingDataAthletes: false,
  loadinggetDataAthletes: false,
  itemsDataAthletes: [],
  itemsDataAthletesXlsxResult: [],
  athletes_type_document: null,
  athletes_document: null,
  athletes_names: null,
  athletes_father_last_name: null,
  athletes_mother_last_name: null,
  athletes_date_of_bird: null,
  athletes_id_pais: null,
  athletes_id_state: null,
  athletes_id_city: null,
  athletes_id_town: null,
  athletes_address: null,
  athletes_phone: null,
  athletes_email: null,
  athletes_gender: null,
  athletes_physical_height: null,
  athletes_physical_weight: null,
  athletes_number_people: null,
  athletes_sizes_footwear: null,
  athletes_sizes_pole: null,
  athletes_sizes_pants: null,
  athletes_entitie_id_federation: null,
  athletes_entitie_id_league: null,
  athletes_entitie_id_club: null,
  itemsDataAthletesXlsx: [],
  athletes_search: "",
  athletes_selected: "",

  itemsInscriptions: [],
  loadingItemsInscriptions: false,

  spinner_information: false,

  athletes_file_modal_id: false,
  athletes_file_modal_name: null,

  //MODAL
  athletes_modal_id_photo: false,
  athletes_modal_id_league: false,
  athletes_modal_import_data: false,

  itemsDataTests: [],
  loadingitemsDataTests: false,

  itemsDataAthletesOne: [],
};

const mutations = {

  SET_ATHLETES_TYPE_DOCUMENT(state, payload) {
    state.athletes_type_document = payload;
  },
  SET_ATHLETES_DOCUMENT(state, payload) {
    state.athletes_document = payload;
  },
  setAthletes(state, payload) {
    state.loadingDataAthletes = true;
    var vm = this;
    var data = JSON.stringify({
      id_type_document: state.athletes_type_document,
      document: state.athletes_document,
      names: state.athletes_names,
      last_name: state.athletes_father_last_name,
      mother_last_name: state.athletes_mother_last_name,
      id_gender: state.athletes_gender,
      birthday: state.athletes_date_of_bird,
      id_pais_location: state.athletes_id_pais,
      id_state_location: state.athletes_id_state,
      id_city_location: state.athletes_id_city,
      id_town_location: state.athletes_id_town,
      address_location: state.athletes_address,
      height: state.athletes_physical_height,
      weight: state.athletes_physical_weight,
      footwear: state.athletes_sizes_footwear,
      pole_size: state.athletes_sizes_pole,
      pants_size: state.athletes_sizes_pants,
      mail: state.athletes_email,
      phone: state.athletes_phone,
      status: 1,
      id_organization: payload.id_org,
      id_photo: state.athletes_id_photo,
      id_federation: state.athletes_entitie_id_federation,
      id_league: state.athletes_entitie_id_league,
      id_club: state.athletes_entitie_id_club,
      itemsRoles: [
        {
          id_role: 3,
        },
      ],
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/people/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "athlete created successfully",
          showConfirmButton: true,
        });

        vm.commit("athletes/cleanAthletes");
        router.back();
        state.loadingDataAthletes = false;
      })
      .catch(function (error) {
        state.loadingDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  setAthletesMultiple(state, payload) {
    state.loadingDataAthletes = true;
    var vm = this;
    var data = JSON.stringify({
      itemsathletes: state.itemsDataAthletesXlsx,
      status: 1,
      id_organization: payload.id_organization,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_API + "/athletes/multiple/add",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "athletes imported successfully",
          showConfirmButton: true,
        });

        state.itemsDataAthletesXlsxResult = response.data.success;

        vm.commit("athletes/getAthletes", { id_org: payload.id_organization });
      })
      .catch(function (error) {
        state.loadingDataAthletes = false;
        console.log(error.response.data.message);
      });
  },

  updateAthletes(state, payload) {
    state.loadingDataAthletes = true;
    var vm = this;
    var data = JSON.stringify({
      id_type_document: state.athletes_type_document,
      document: state.athletes_document,
      names: state.athletes_names,
      last_name: state.athletes_father_last_name,
      mother_last_name: state.athletes_mother_last_name,
      id_gender: state.athletes_gender,
      birthday: state.athletes_date_of_bird,
      id_pais_location: state.athletes_id_pais,
      id_state_location: state.athletes_id_state,
      id_city_location: state.athletes_id_city,
      id_town_location: state.athletes_id_town,
      address_location: state.athletes_address,
      height: state.athletes_physical_height,
      weight: state.athletes_physical_weight,
      footwear: state.athletes_sizes_footwear,
      pole_size: state.athletes_sizes_pole,
      pants_size: state.athletes_sizes_pants,
      mail: state.athletes_email,
      phone: state.athletes_phone,
      status: 1,
      id_organization: payload.id_org,
      id_photo: state.athletes_id_photo,
      id_federation: state.athletes_entitie_id_federation,
      id_league: state.athletes_entitie_id_league,
      id_club: state.athletes_entitie_id_club,
      itemsRoles: [
        {
          id_people: payload.id,
          id_role: 3,
        },
      ],
    });
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_API + "/people/update/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "Atleta actualizada éxitosamente",
          showConfirmButton: true,
        });

        vm.commit("athletes/cleanAthletes");
        router.back();

        state.loadingDataAthletes = false;
      })
      .catch(function (error) {
        state.loadingDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  deleteAthletes(state, payload) {
    state.loadingDataAthletes = true;
    var vm = this;
    let data = {
      status: 0,
    };
    var config = {
      method: "delete",
      url: process.env.VUE_APP_URL_API + "/people/delete/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        Vue.swal({
          position: "top-end",
          icon: "success",
          title: "athletes successfully removed",
          showConfirmButton: true,
        });

        vm.commit("athletes/getAthletes", { id_org: payload.id_org });

        state.loadingDataAthletes = false;
      })
      .catch(function (error) {
        state.loadingDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getListAthletes(state) {
    state.loadinggetDataAthletes = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API +
        "/people/filter/1" ,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataAthletes = response.data.data;

        state.loadinggetDataAthletes = false;
      })
      .catch(function (error) {
        state.loadinggetDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getAthletes(state, payload) {
    state.loadinggetDataAthletes = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API +
        "/people/filter/1/3/" +
        payload.id_org +
        "/" +
        payload.id_league,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataAthletes = response.data.data;

        state.loadinggetDataAthletes = false;
      })
      .catch(function (error) {
        state.loadinggetDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getAthletesClub(state, payload) {
    state.loadinggetDataAthletes = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API +
        "/people/filter/1/3/" +
        payload.id_org +
        "/club/" +
        payload.id_league,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataAthletes = response.data.data;

        state.loadinggetDataAthletes = false;
      })
      .catch(function (error) {
        state.loadinggetDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getAthletesFilter(state, payload) {
    state.loadinggetDataAthletes = true;
    var vm = this;
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_API +
        "/people/filter/1/" +
        payload.id_filter +
        "/" +
        payload.id_org +
        "/0",
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.itemsDataAthletes = response.data.data;

        state.loadinggetDataAthletes = false;
      })
      .catch(function (error) {
        state.loadinggetDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  getAthletesOne(state, payload) {
    state.loadinggetDataAthletes = true;
    state.spinner_information = true;

    var vm = this;
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_API + "/people/one/" + payload.id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios(config)
      .then(function (response) {
        state.athletes_type_document = response.data.data[0].document.id;
        state.athletes_document = response.data.data[0].document.number;
        state.athletes_names = response.data.data[0].names;
        state.athletes_father_last_name = response.data.data[0].last_name;
        state.athletes_mother_last_name =
          response.data.data[0].mother_last_name;
        state.athletes_gender = response.data.data[0].gender.id;
        state.athletes_date_of_bird = response.data.data[0].birthday;
        state.athletes_id_pais = response.data.data[0].location.pais.id;
        if (response.data.data[0].location.pais.id) {
          vm.commit("state/getState", {
            id_pais: response.data.data[0].location.pais.id,
          });
        }
        state.athletes_id_state = response.data.data[0].location.state.id;
        if (response.data.data[0].location.state.id) {
          vm.commit("city/getCity", {
            id_state: response.data.data[0].location.state.id,
          });
        }
        state.athletes_id_city = response.data.data[0].location.city.id;
        if (response.data.data[0].location.city.id) {
          vm.commit("town/getTown", {
            id_city: response.data.data[0].location.city.id,
          });
        }
        state.athletes_id_town = response.data.data[0].location.town.id;
        state.athletes_address = response.data.data[0].location.address;
        state.athletes_physical_height = response.data.data[0].sizes.height;
        state.athletes_physical_weight = response.data.data[0].sizes.weight;
        state.athletes_sizes_footwear = response.data.data[0].sizes.footwear;
        state.athletes_sizes_pole = response.data.data[0].sizes.pole_size;
        state.athletes_sizes_pants = response.data.data[0].sizes.pants_size;
        state.athletes_email = response.data.data[0].contact.mail;
        state.athletes_phone = response.data.data[0].contact.phone;
        state.athletes_entitie_id_federation =
          response.data.data[0].organization.id_federation;
        vm.commit("league/getLeague", {
          id_org: response.data.data[0].organization.id_federation,
        });
        state.athletes_entitie_id_league =
          response.data.data[0].organization.id_league;

        vm.commit("clubes/getClubesLeague", {
          id_league: response.data.data[0].organization.id_league,
        });

        state.athletes_entitie_id_club =
          response.data.data[0].organization.id_club;
        state.athletes_file_modal_name = response.data.data[0].photo.route;
        state.itemsDataAthletesOne = response.data.data;

        if (payload.id_event) {
          state.loadingitemsDataTests = true;
          vm.dispatch("tests/getEventsTestCate", {
            id_category: response.data.data[0].id_category_required,
            id_gender: response.data.data[0].gender.id,
            id_event: payload.id_event,
            id_athlete: response.data.data[0].id,
          }).then((response) => {
            state.itemsDataTests = response;
            state.loadingitemsDataTests = false;
          });
        }

        state.spinner_information = false;

        state.loadinggetDataAthletes = false;
      })
      .catch(function (error) {
        state.loadinggetDataAthletes = false;
        Vue.swal({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  },

  cleanAthletes(state) {
    state.athletes_type_document = null;
    state.athletes_document = null;
    state.athletes_names = null;
    state.athletes_father_last_name = null;
    state.athletes_mother_last_name = null;
    state.athletes_date_of_bird = null;
    state.athletes_id_pais = null;
    state.athletes_id_state = null;
    state.athletes_id_city = null;
    state.athletes_id_town = null;
    state.athletes_address = null;
    state.athletes_phone = null;
    state.athletes_email = null;
    state.athletes_gender = null;
    state.athletes_physical_height = null;
    state.athletes_physical_weight = null;
    state.athletes_number_people = null;
    state.athletes_sizes_footwear = null;
    state.athletes_sizes_pole = null;
    state.athletes_sizes_pants = null;
    state.athletes_entitie_id_federation = null;
    state.athletes_entitie_id_league = null;
    state.athletes_entitie_id_club = null;
    state.athletes_file_modal_id = false;
    state.athletes_file_modal_name = null;
    state.itemsDataAthletesXlsx = [];
  },
};

const actions = {
  setAthletes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setAthletes", payload);
      resolve();
    });
  },

  setAthletesMultiple({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("setAthletesMultiple", payload);
      resolve();
    });
  },

  updateAthletes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("updateAthletes", payload);
      resolve();
    });
  },

  deleteAthletes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("deleteAthletes", payload);
      resolve();
    });
  },

  cleanAthletes({ commit }) {
    return new Promise((resolve, reject) => {
      commit("cleanAthletes");
      resolve();
    });
  },

  getListAthletes({ commit }) {
    return new Promise((resolve, reject) => {
      commit("getListAthletes");
      resolve();
    });
  },
  getAthletes({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getAthletes", payload);
      resolve();
    });
  },

  getAthletesClub({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getAthletesClub", payload);
      resolve();
    });
  },

  getAthletesFilter({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getAthletesFilter", payload);
      resolve();
    });
  },

  getAthletesOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("getAthletesOne", payload);
      resolve();
    });
  },

  getAtheleteForLeague({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadinggetDataAthletes = true;
      var vm = this;
      let data = {
        league: payload.id_league,
        club: payload.id_club,
      };
      var config = {
        method: "post",
        url: process.env.VUE_APP_URL_API + "/people/lc/1/3/" + payload.id_org,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          resolve(response.data.data);
          state.loadinggetDataAthletes = false;
        })
        .catch(function (error) {
          state.loadinggetDataAthletes = false;
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        });
    });
  },

  getAtheleteWithInscriptions({ state }, payload) {
    return new Promise((resolve, reject) => {
      state.loadingItemsInscriptions = true;

      var config = {
        method: "get",
        url:
          process.env.VUE_APP_URL_API +
          "/people/inscriptions/" +
          payload.idPeople +
          "/" +
          payload.idEvent,
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios(config)
        .then(function (response) {
          state.itemsInscriptions = response.data.data;
          state.loadingItemsInscriptions = false;
          resolve(response.data.data);
        })
        .catch(function (error) {
          Vue.swal({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
          state.loadingItemsInscriptions = false;
        });
    });
  },
  getAthleteDocument({ state }, payload) {
    state.loadinggetDataAthletes = true;

    return axios
        .get(process.env.VUE_APP_URL_API + "/people/document/1/" + payload.document, {
            headers: { "Content-Type": "application/json" },
        })
        .then((response) => {
            state.AthleteDocument = response.data.document; // Ahora accedemos correctamente a response.data.document
            state.loadinggetDataAthletes = false;
            return response.data; // Retornamos los datos para usarlos en setAthletes()
        })
        .catch((error) => {
            state.loadinggetDataAthletes = false;
            Vue.swal({
                icon: "error",
                title: "Oops...",
                text: error.response?.data?.message || "Error desconocido al obtener el documento.",
            });
            return null; // Retorna null en caso de error
        });
},

};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
